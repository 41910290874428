import React from 'react'
import { useTranslation } from 'react-i18next'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/ContactForm'

function SecondPage() {
    const { t } = useTranslation('contact')

    return (
        <Layout>
            <SEO title="title" />
            {/* <h1 className="pageTitle">{t('title')}</h1> */}
            {/* <ContactForm /> */}
            <div className="contactPreview">
                <div className="row">
                    <div className="column">
                        <h1>{t('title')}</h1>
                        <p>{t('description')}</p>
                        <ContactForm />
                    </div>
                    <div className="column columnhigh">
                        <h3>{t('platforms')}</h3>
                        <ul>
                            <li>
                                <a href="https://www.linkedin.com/in/spankowski/">Linkedin</a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/spankowski/">Github</a>
                            </li>
                            <li>
                                <a href="https://www.goodreads.com/spankowski ">GoodReads</a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/spankowski.dev/">Instagram</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default SecondPage
